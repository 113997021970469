import { MaterialSymbolsBreakingNews } from '@/assets/icons/comm/MaterialSymbolsBreakingNews';
import { MaterialSymbolsCircleNotifications } from '@/assets/icons/comm/MaterialSymbolsCircleNotifications';
import { MaterialSymbolsFinanceModeRounded } from '@/assets/icons/mine/MaterialSymbolsFinanceModeRounded';
import PageEnum from '@/enums/pageEnum';
import { getNoticeType } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useModel } from '@umijs/max';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useMessageStateHooks = () => {
  /**
   * 消息分类
   */
  const { t } = useTranslation();
  const { data: noticeTypeList = [] as any, loading } = useReq(
    getNoticeType,
    {},
  );
  const { noReading } = useModel('socket');
  const { gotoGlobalCustomerService } = useModel('imSocket');
  // 消息分类
  const keyList: any = [
    {
      code: 'SYSTEM_NOTICE',
      i18Title: t('公告'),
      icon: (
        <MaterialSymbolsCircleNotifications></MaterialSymbolsCircleNotifications>
      ),
      path: PageEnum.ANNOUNCEMENT,
    },
    // {
    //   code: 'SYSTEM_NEWS',
    //   i18Title: t('新闻'),
    //   icon: <MaterialSymbolsBreakingNews></MaterialSymbolsBreakingNews>,
    //   path: PageEnum.NEWS,
    // },
    {
      code: 'SYSTEM_SERVER',
      i18Title: t('客服'),
      icon: <MaterialSymbolsBreakingNews></MaterialSymbolsBreakingNews>,
      onClick() {
        gotoGlobalCustomerService();
      },
    },
  ];
  // 公告列表
  const messageTypeList: any = useMemo(() => {
    if (!noticeTypeList || noticeTypeList?.length === 0) return [];
    let arr = [];
    arr = Array.isArray(noticeTypeList)
      ? keyList?.filter(Boolean).map((item: any) => {
        const findItem =
          noticeTypeList?.find((k: any) => k?.code === item?.code) ?? {};
        return { ...item, ...findItem };
      })
      : [];
    if (arr.length === 0)
      return [
        ...keyList,
        {
          code: 'SYSTEM_ORDER',
          i18Title: t('订单'),
          icon: (
            <MaterialSymbolsFinanceModeRounded></MaterialSymbolsFinanceModeRounded>
          ),
          path: PageEnum.ORDERMESSAGE,
          msgNum: +noReading <= 0 ? undefined : noReading,
        },
      ];
    arr = [
      ...arr,
      //增加客服
      // {
      //   code: 'SYSTEM_SERVER',
      //   i18Title: t('客服'),
      //   icon: <MaterialSymbolsHeadphones />,
      //   path: PageEnum.ONLINESUPPORT,
      //   title: t('您有新的消息'),
      //   msgNum:
      //     +customerServiceMessageUNREAD === 0
      //       ? undefined
      //       : customerServiceMessageUNREAD,
      // },
      {
        code: 'SYSTEM_ORDER',
        i18Title: t('消息'),
        icon: (
          <MaterialSymbolsFinanceModeRounded></MaterialSymbolsFinanceModeRounded>
        ),
        path: PageEnum.ORDERMESSAGE,
        msgNum: +noReading <= 0 ? undefined : noReading,
      },
    ];
    return arr;
  }, [noticeTypeList, noReading]);

  return {
    messageTypeList,
    loading,
  };
};

