import BaseSkeleton from '@/components/base/baseSkeleton';
import BaseTopNav from '@/components/base/baseTopNav';
import { history } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import { useMessageStateHooks } from './useStateHooks';

/**
 * 消息 类型 公告订单 ，客服
 */
export default () => {
  const { t } = useTranslation();
  const { messageTypeList, loading } = useMessageStateHooks();
  return (
    <div className=" flex-1 flex flex-col">
      <BaseTopNav title={t('消息')}></BaseTopNav>
      <div className=" flex-1 flex flex-col  gap-4 mt-4">
        {messageTypeList?.map((item: any) => {
          return (
            <div
              key={item?.title}
              className=" flex items-center justify-between px-4 py-3"
              onClick={() => {
                  if(item?.path){
                      history.push(item?.path);
                  }
                  if(item?.onClick){
                      item?.onClick?.()
                  }
              }}
            >
              <div className="flex items-center justify-center  bg-backgroundAuxiliaryColor  rounded-md text-xl w-[40px] h-[40px]">
                {item?.icon}
              </div>
              <div className=" flex-1 flex flex-col h-full justify-between w-0">
                <div className=" ml-2 text-base text-titleColor font-bold flex justify-between items-center">
                  <span>{item?.i18Title}</span>

                  {item?.msgNum && (
                    <div className="text-white rounded-full w-[24px] h-[24px] flex justify-center items-center text-xs bg-errorColor">
                      {item?.msgNum}
                    </div>
                  )}
                </div>
                <div className=" flex-1 flex items-center gap-4">
                  <div className=" flex-1 ml-2 text-auxiliaryTextColor text-xs text-ellipsis overflow-hidden break-words whitespace-nowrap">
                    {item?.title}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {
          <BaseSkeleton isLoaded={loading && +messageTypeList?.length === 0}>
            <div className="flex flex-col justify-between gap-4 mt-4 px-4">
              <div className="h-[40px] flex items-center  rounded-md">
                <div className="w-[40px] bg-backgroundAuxiliaryColor h-[40px] rounded-md"></div>
                <div className="w-full h-full bg-backgroundAuxiliaryColor rounded-md ml-2"></div>
              </div>
              <div className="h-[40px] flex items-center  rounded-md">
                <div className="w-[40px] bg-backgroundAuxiliaryColor h-[40px] rounded-md"></div>
                <div className="w-full h-full bg-backgroundAuxiliaryColor rounded-md ml-2"></div>
              </div>
              <div className="h-[40px] flex items-center rounded-md">
                <div className="w-[40px] bg-backgroundAuxiliaryColor h-[40px] rounded-md"></div>
                <div className="w-full h-full bg-backgroundAuxiliaryColor rounded-md ml-2"></div>
              </div>
            </div>
          </BaseSkeleton>
        }
      </div>
    </div>
  );
};
